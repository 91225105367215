import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { DIMENSION_FIELD_TYPES, DIMENSION_SCOPE_TYPES } from '../../constants';
import { get, post, put, remove } from '../../utils/backend';
import { toString } from '../../utils/helpers';
import { useStandardDimensions } from '../standardDimensions';
import { UserContext } from '../user';

type UserDimensionTranslationRes = {
  id: number | null;
  organizationId: number | null;
  dimensionId: number;
  locale: string;
  localeDisplayName: string;
  localeDescription: string | null;
};

type UserDimensionOptionRes = {
  id: number;
  name: string;
  order: number;
  translations: {
    name: string;
    locale: string;
    id: number;
  }[];
};

export interface UserDimensionRes {
  id: number;
  scope: string;
  type: string;
  visible: boolean;
  status: number;
  dimension_code: string;
  value: string | null;
  userId: string | null;
  description: string | null;
  only_selfregistration: boolean;
  order_num: number;
  required: boolean;
  others: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userDimValues: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  otherUserDimValues: any;
  parentId: number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hierarchy: any;
  userCount: number;
  translations: UserDimensionTranslationRes[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locales: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  examples: any;
  internal: boolean;
  optionList: UserDimensionOptionRes[] | null;
  display_name: string;
  isDei: boolean;
}

export interface DeleteUserDimensionsReq {
  id: number;
  type: string;
  display_name: string;
}

export interface CreateUserDimensionReq {
  id?: number;
  isDei: boolean;
  type: string;
  status: number;
  translations: UserDimensionTranslationRes[];
}

export interface UserDimensionUI {
  code: string;
  description: string | null;
  id: number;
  isDei: boolean;
  key: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locales: any;
  name: string;
  registrationOptions: UserDimensionOptionRes[];
  scope?: string;
  status: number;
  type?: string;
}

interface UserDimensionsContextType {
  userDimensions: UserDimensionRes[];
  realUserDimensions: UserDimensionRes[];
  saveDimensions: (data: Array<CreateUserDimensionReq>) => Promise<Array<number>>;
  deleteUserDimension: (body: DeleteUserDimensionsReq) => Promise<number>;
  loading: boolean;
  refetchUserDimensions: () => Promise<void>;
  fetchError: Error | undefined;
  createOrUpdateError: Error | undefined;
  deleteError: Error | undefined;
}

const UserDimensionsContext = createContext<UserDimensionsContextType | undefined>(undefined);

export const userDimensionResToUI = (item: UserDimensionRes): UserDimensionUI => ({
  code: item.dimension_code,
  description: item.description,
  id: item.id,
  isDei: item.isDei,
  key: item.id,
  locales: item.locales,
  name: toString(item.display_name),
  registrationOptions: item.optionList ?? [],
  scope: (DIMENSION_SCOPE_TYPES.find((type) => type.value === item.scope) || {}).text,
  status: item.status,
  type: (DIMENSION_FIELD_TYPES.find((type) => type.value === item.type) || {}).text,
});

export const excludedCodesFromRealUserDimensions = [
  'firstname',
  'lastname',
  'email',
  'alternative_email',
  'mobile',
];

export function filterRealUserDimensions(userDimensions: UserDimensionRes[]): UserDimensionRes[] {
  return userDimensions.filter(
    (x) => !excludedCodesFromRealUserDimensions.includes(x.dimension_code),
  );
}

export async function fetchUserDimensions(realOnly = false): Promise<UserDimensionRes[]> {
  const res = await get('/users/user_dimensions_with_translations', { browserCache: true });
  return realOnly ? filterRealUserDimensions(res) : res;
}

interface UserDimensionsProviderProps {
  children: React.ReactNode;
}

export const UserDimensionsProvider: React.FC<UserDimensionsProviderProps> = ({ children }) => {
  const { refetchStandardDimensions } = useStandardDimensions();
  const [userDimensions, setUserDimensions] = useState<UserDimensionRes[]>([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const [fetchError, setFetchError] = useState<Error | undefined>(undefined);
  const [createOrUpdateError, setCreateOrUpdateError] = useState<Error | undefined>(undefined);
  const [deleteError, setDeleteError] = useState<Error | undefined>(undefined);
  const refetchUserDimensions: () => Promise<void> = useCallback(async () => {
    if (!user?.userId) return;
    try {
      setLoading(true);
      const res = await get('/users/user_dimensions_with_translations', { browserCache: false });
      setUserDimensions(res);
    } catch (error) {
      setFetchError(error as Error);
      // eslint-disable-next-line no-console
      console.error('Failed to fetch dimensions:', error);
    } finally {
      setLoading(false);
    }
  }, [user?.userId]);

  const realUserDimensions = useMemo(
    () => filterRealUserDimensions(userDimensions),
    [userDimensions],
  );

  const createOrUpdateUserDimension = async (
    savingDimensions: Array<CreateUserDimensionReq>,
  ): Promise<Array<number>> => {
    const method = savingDimensions.every((dim) => dim.id) ? put : post;
    const dimensionIds: Array<number> = await method('/dimension_metadata', savingDimensions);
    return dimensionIds;
  };

  const saveDimensions = async (
    dimensions: Array<CreateUserDimensionReq>,
  ): Promise<Array<number>> => {
    setLoading(true);
    const newDimensions = dimensions.filter((dim) => !dim.id);
    const updateDimensions = dimensions.filter((dim) => dim.id);
    const callPromises = [
      ...(newDimensions.length ? [createOrUpdateUserDimension(newDimensions)] : []),
      ...(updateDimensions.length ? [createOrUpdateUserDimension(updateDimensions)] : []),
    ];

    try {
      const dimensionIds: Array<number> = await Promise.all(callPromises).then(
        (responses: Array<Array<number>>) => responses.reduce((acc, ids) => [...acc, ...ids], []),
      );

      await Promise.all([refetchStandardDimensions(), refetchUserDimensions()]).then(() =>
        setLoading(false),
      );
      return dimensionIds;
    } catch (e) {
      setCreateOrUpdateError(e as Error);
      setLoading(false);
      throw e;
    }
  };

  const deleteUserDimension = async (body: DeleteUserDimensionsReq): Promise<number> => {
    try {
      setLoading(true);
      const res = await remove('/dimension_metadata', body);
      await Promise.all([refetchStandardDimensions(), refetchUserDimensions()]);
      return res;
    } catch (e) {
      setDeleteError(e as Error);
      setLoading(false);
      throw e;
    }
  };

  useEffect(() => {
    refetchUserDimensions();
  }, [refetchUserDimensions, user?.userId]);

  const value = useMemo(
    () => ({
      createOrUpdateError,
      deleteError,
      deleteUserDimension,
      fetchError,
      loading,
      realUserDimensions,
      refetchUserDimensions,
      saveDimensions,
      userDimensions,
    }),
    [userDimensions, saveDimensions, deleteUserDimension, loading, fetchError],
  );
  return <UserDimensionsContext.Provider value={value}>{children}</UserDimensionsContext.Provider>;
};

interface UseUserDimensionsProps {
  onFetchError?: (error: Error) => void;
  onCreateOrUpdateError?: (error: Error) => void;
  onDeleteError?: (error: Error) => void;
}

export const useUserDimensions = (props?: UseUserDimensionsProps): UserDimensionsContextType => {
  const context = useContext(UserDimensionsContext);
  if (!context) {
    throw new Error('useUserDimensions must be used within a UserDimensionsProvider');
  }
  useEffect(() => {
    if (context.fetchError && props?.onFetchError) {
      props.onFetchError(context.fetchError);
    }
  }, [context.fetchError]);

  useEffect(() => {
    if (context.createOrUpdateError && props?.onCreateOrUpdateError) {
      props.onCreateOrUpdateError(context.createOrUpdateError);
    }
  }, [context.createOrUpdateError]);

  useEffect(() => {
    if (context.deleteError && props?.onDeleteError) {
      props.onDeleteError(context.deleteError);
    }
  }, [context.deleteError]);
  return context;
};
