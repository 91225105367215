import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { App as AntApp, ConfigProvider } from 'antd';

import 'core-js/stable';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import './utils/polyfills';

import { CommonProvider } from './store/common';
import { DiscussionMaterialsProvider } from './store/discussionMaterials';
import { FiltersProvider } from './store/filters';
import { HrisProvider } from './store/hris';
import { OrgProvider } from './store/organization';
import { StandardDimensionsProvider } from './store/standardDimensions';
import { DEIThemeProvider } from './store/themesDEI';
import { UserProvider } from './store/user';
import { UserDimensionsProvider } from './store/userDimensions';
import App from './App';
import { appTheme } from './appTheme';

ReactDOM.render(
  <ConfigProvider theme={appTheme}>
    <UserProvider>
      <DEIThemeProvider>
        <CommonProvider>
          <HrisProvider>
            <FiltersProvider>
              <DiscussionMaterialsProvider>
                <StandardDimensionsProvider>
                  <UserDimensionsProvider>
                    <OrgProvider>
                      <AntApp>
                        <Router>
                          <App />
                        </Router>
                      </AntApp>
                    </OrgProvider>
                  </UserDimensionsProvider>
                </StandardDimensionsProvider>
              </DiscussionMaterialsProvider>
            </FiltersProvider>
          </HrisProvider>
        </CommonProvider>
      </DEIThemeProvider>
    </UserProvider>
  </ConfigProvider>,
  document.getElementById('root'),
);
