import { FC, memo, ReactNode, useEffect } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import classNames from 'classnames';

import Footer from '../Footer';
import Navbar from '../Navbar';

import './styles.scss';

interface HeaderProps {
  title: ReactNode;
  tooltip?: string;
  subtitle?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
  className?: string;
}

const Header: FC<HeaderProps> = memo(
  ({ title, tooltip, subtitle, actions = null, children, className }) => (
    <div className={classNames('page__header', className)}>
      <div className="page__header-container">
        <div className="page__header-title">
          <h1>
            {title}
            {tooltip && (
              <Tooltip title={tooltip} placement="right">
                <InfoCircleOutlined className="hint" />
              </Tooltip>
            )}
          </h1>
          {!!subtitle && <div className="page__header-subtitle">{subtitle}</div>}
        </div>
        {!!actions && <div className="page__header-actions">{actions}</div>}
      </div>

      {!!children && <div>{children}</div>}
    </div>
  ),
);

interface BodyProps {
  children: ReactNode;
}

const Body: FC<BodyProps> = memo(({ children }) => <div className="page__body">{children}</div>);

interface SectionProps {
  className?: string;
  children?: ReactNode;
  minHeight?: number;
}

const Section: FC<SectionProps> = memo(({ className, children, minHeight }) => (
  <div
    className={classNames('page__section', className)}
    style={{ minHeight: `${minHeight}px` || 'auto' }}
  >
    {children}
  </div>
));

interface SectionTitleProps {
  className?: string;
  children: ReactNode;
}

const SectionTitle: FC<SectionTitleProps> = memo(({ className, children }) => (
  <div className={classNames('page__section__title', className)}>{children}</div>
));

interface PageProps {
  className?: string;
  children: ReactNode;
  hideNavbar?: boolean;
  title?: string;
}

type PageType = FC<PageProps> & {
  Section: FC<SectionProps>;
  Body: FC<BodyProps>;
  Header: FC<HeaderProps>;
  SectionTitle: FC<SectionTitleProps>;
};

const PageComponent: FC<PageProps> = memo(({ className, children, hideNavbar = false, title }) => {
  useEffect(() => {
    document.title = title ?? 'Entromy';
  }, [title]);
  return (
    <div className={classNames('page', className, { 'page--with-navbar': !hideNavbar })}>
      {!hideNavbar && <Navbar />}
      <Body>{children}</Body>
      <Footer />
    </div>
  );
});

const Page = PageComponent as PageType;

Page.Section = Section;
Page.Body = Body;
Page.Header = Header;
Page.SectionTitle = SectionTitle;

export default Page;
