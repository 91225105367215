import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { get } from '../../utils/backend';
import { toString } from '../../utils/helpers';
import { UserContext } from '../user';

import { SurveysRes } from './shared';

interface OrgContextType {
  surveys: SurveysRes[];
  fetchOrgContext: () => Promise<void>;
}

export const OrgContext = createContext<OrgContextType | undefined>(undefined);

interface OrgProviderProps {
  children: React.ReactNode;
}

export const OrgProvider = ({ children }: OrgProviderProps) => {
  const [surveys, setSurveys] = useState<SurveysRes[]>([]);
  const { user } = useContext(UserContext);

  async function fetchOrgContext(): Promise<void> {
    const data = await get(`/common/org_user_context/${user.orgId}`);
    setSurveys(data.surveys);
  }

  useEffect(() => {
    if (!user.orgId) {
      return;
    }
    fetchOrgContext();
  }, [user.userId, user.orgId]);

  const value = useMemo(
    () => ({
      fetchOrgContext,
      surveys,
    }),
    [fetchOrgContext, surveys],
  );

  return <OrgContext.Provider value={value}>{children}</OrgContext.Provider>;
};
